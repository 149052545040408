import styled from 'styled-components'

import { fluidFont } from '../styles'

export default styled.p`
  margin: 0;
  padding: 0;
  ${fluidFont(18, 19)};
  font-weight: 400;
  line-height: 1.4;
  color: ${({ theme }) => theme.text.regular};
  margin-top: 8px;
`