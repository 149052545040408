import styled from 'styled-components'

import { fluidFont } from '../../styles'

const Title = styled.h1`
  text-align: left;
  font-weight: 700;
  ${fluidFont(28, 32)};
  color: ${({ theme }) => theme.title};
  margin: 0;
`

export default Title
