import React from 'react'
import Message from '../shared/message'

const Delete = () => {
  return (
    <Message title="Goodbye!">
      Your account was successfully removed.
      <br />
      We hope to see you again one day!
    </Message>
  )
}

export default Delete