import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import styled from 'styled-components'
import { useParams, Link as unstyledLink, Redirect } from 'react-router-dom'

import Wrapper from './components/Wrapper'
import Title from './components/Title'
import TextInput from '../shared/text-input'
import Submit from '../shared/button-input'
import { Error } from './components/Message'
import RESET_PASSWORD from './queries/ResetPassword.gql'
import Loading from '../shared/loading'
import Message from '../shared/message'
import { link } from '../styles'
import { getUserUrl } from '../utils/url'
import { useCurrentUserId } from './hooks'

const Link = styled(unstyledLink)`
  ${link};
  font-size: inherit;
  font-weight: inherit;
`

const ResetPassword = () => {
  const currentUserId = useCurrentUserId()
  const { userId, resetToken } = useParams()
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState()

  const [resetPassword, { loading, data, error }] = useMutation(RESET_PASSWORD)

  if (currentUserId) {
    return <Redirect to={getUserUrl(currentUserId)} />
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (password === '') {
      setPasswordError('Provide an password')
      return
    }

    if (password.length < 5) {
      setPasswordError(
        `Come on a password with just ${password.length} characters... Try harder`
      )
      return
    }

    resetPassword({ variables: { newPassword: password, userId, resetToken } })
  }

  if (error) {
    return (
      <Message title="Sorry the reset failed">
        A reset link can only be used once.
        <br />
        Make sure to use the URL provided by email.
      </Message>
    )
  }

  if (data && data.resetPassword.success) {
    return (
      <Message title="Successful reset">
        Your password was successfully reset.
        <br />
        You can now <Link to="/login">login</Link> with your new password.
      </Message>
    )
  }

  return (
    <Wrapper>
      <Title css="margin-bottom: 20px;">Reset password</Title>
      <form>
        <div css="margin-bottom: 30px;">
          <TextInput
            type="password"
            placeholder="Your new password"
            value={password}
            required
            onChange={e => setPassword(e.target.value)}
            autoComplete="new-password"
          />
          {passwordError && <Error>{passwordError}</Error>}
        </div>
        <Submit
          as="input"
          type="submit"
          onClick={handleSubmit}
          value="Reset password"
        />
      </form>
      {loading && <Loading />}
    </Wrapper>
  )
}

export default ResetPassword
