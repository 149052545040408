import { createGlobalStyle } from 'styled-components'
import { normalize } from 'polished'

import { InterFont, fontVariables } from './fonts'

export default createGlobalStyle`
  ${normalize()};

  ${InterFont};
  ${fontVariables};

  *,
  *:before,
  *:after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    -webkit-overflow-scrolling: touch;
  }

  html,
  body {
    width: 100vw;
    background-color: ${({ theme }) => theme.background};
    font-family: var(--font-modern);
  }

    html {
    margin-right: 0;
    margin-left: 0;
  }


  body {
    box-sizing: border-box;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga' 1, 'case' 1, 'calt' 1;
    text-align: left;
    overflow-x: hidden;
  }

  #root {
    min-height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .katex-display {
    &&& {
      margin: 0;
    }
  }

`
