import styled, { css } from 'styled-components'
import { transparentize as fade } from 'polished'
import { fluidFont } from '../styles'

const EMPTY_CHARACTER = '\u200B'

const TableCell = styled.div`
  padding: 8px;
  display: flex;
  height: 100%;
  white-space: nowrap;
  align-items: center;
  justify-content: ${({ align }) => {
    if (align === 'left') return 'flex-start'
    if (align === 'right') return 'flex-end'
    return align
  }};

  ${({ subtle, theme }) =>
    subtle &&
    css`
      color: ${fade(0.3, theme.title)};
    `};
`

TableCell.defaultProps = {
  children: EMPTY_CHARACTER,
  subtle: false,
  align: 'left',
}

export { TableCell }

const TableHighlight = styled(TableCell)`
  background-color: ${({ theme }) => theme.surface.opacity};
`

TableHighlight.defaultProps = {
  children: EMPTY_CHARACTER,
}


export { TableHighlight }

const TableHeader = styled(TableHighlight)`
  color: ${({ theme }) => fade(0.3, theme.title)};
  text-transform: uppercase;
  ${fluidFont(15, 15)};
  font-weight: 600;
`

TableHeader.defaultProps = {
  children: EMPTY_CHARACTER,
}


export { TableHeader }

const Table = styled.div`
  width: 100%;
  overflow: auto;
  text-align: left;
  ${fluidFont(18, 18)};
  font-weight: 500;
  color: ${({ theme }) => theme.title};
  display: flex;
  flex-direction: row;
  display: grid;
  grid-gap: 6px 0;
  padding-bottom: 8px;
  grid-template-columns: ${({ columns }) => {
    if (typeof columns === 'number') {
      return '1fr '.repeat(columns)
    }

    return columns
  }};

  ${({ columns }) => {
    const numColumns =
      typeof columns === 'number' ? columns : columns.split(' ').length

    return css`
    ${TableCell}:nth-child(${numColumns}n + 1) {
      padding-left: 20px;
    }

    ${TableHighlight}:nth-child(${numColumns}n + 1) {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    ${TableCell}:nth-child(${numColumns}n + ${numColumns}) {
      padding-right: 20px;
    }

    ${TableHighlight}:nth-child(${numColumns}n + ${numColumns}) {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  `
  }};
`

Table.defaultProps = {}

export { Table }
