import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import LoginOrRegister from './LoginOrRegister'
import Message from '../shared/message'
import { getUserUrl } from '../utils/url'
import { useCurrentUserId, useRegister, useLogin } from './hooks'

export const loginOrRegisterPaths = ['/login', '/register']

const LoginOrRegisterHandler = ({ match, location }) => {
  const isRegister = match.path === '/register'

  const currentUserId = useCurrentUserId()
  const [register, registrationState] = useRegister()
  const [login, { error: loginError }] = useLogin()
  const [email, setEmail] = useState()

  const {
    error: registrationError,
    success: registrationSuccess,
  } = registrationState

  const handleSubmit = userData => {
    const fetchFunction = isRegister ? register : login

    if (isRegister) {
      setEmail(userData.email)
    }

    fetchFunction(userData)
  }

  if (currentUserId && location.state && location.state.nextLocation) {
    return <Redirect to={location.state.nextLocation} />
  }

  if (currentUserId) {
    return <Redirect to={getUserUrl(currentUserId)} />
  }

  if (registrationSuccess) {
    return (
      <Message title="You are almost done…">
        We’ve sent an email to {email}.
        <br />
        Open it up to activate your account.
      </Message>
    )
  }

  return (
    <>
      <Helmet>
        <title>{isRegister ? 'Register' : 'Login'} to optimize</title>
      </Helmet>
      <LoginOrRegister
        register={isRegister}
        registerLink="/register"
        loginLink="/login"
        onSubmit={handleSubmit}
        error={
          (loginError && loginError.message) ||
          (registrationError && registrationError.message)
        }
      />
    </>
  )
}

export default LoginOrRegisterHandler
