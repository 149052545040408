import React from 'react'
import styled from 'styled-components'
import { transparentize as fade } from 'polished'

import ErrorIcon from '../icons/Exclamationmark/octagon/fill'
import { contentWrapper, fluidFont } from '../styles'

const GRAPHQL_ERROR = 'GraphQL error: '
const NETWORK_ERROR = 'Network error: '

const Wrapper = styled.div`
  ${contentWrapper};
  ${fluidFont(21, 28)};
  text-align: center;
  margin: 50px auto;
  color: ${({ theme }) => theme.title};
`

const Body = styled.p`
  margin: 0;
  padding: 0;
  ${fluidFont(18, 24)};
  font-weight: 600;
  margin-top: 30px;
  color: ${({ theme }) => fade(0.3, theme.title)};
`

const getMessage = children => {
  if (typeof children !== 'string') {
    return children
  }

  if (children.startsWith(GRAPHQL_ERROR)) {
    return children.slice(GRAPHQL_ERROR.length)
  } else if (children.startsWith(NETWORK_ERROR)) {
    return children.slice(NETWORK_ERROR.length)
  }

  return children
}

const Error = ({ children, ...restProps }) => {
  const message = getMessage(children)

  return (
    <Wrapper {...restProps}>
      <div>
        <ErrorIcon />
      </div>
      {message && <Body>{message}</Body>}
    </Wrapper>
  )
}

export default Error
