import React, { useState } from 'react'
import styled from 'styled-components'
import isEmail from 'validator/lib/isEmail'
import normalizeEmail from 'validator/lib/normalizeEmail'
import { Link as unstyledLink } from 'react-router-dom'

import { link, fluidFont } from '../styles'
import TextInput from '../shared/text-input'
import Submit from '../shared/button-input'
import TitleRow from './components/TitleRow'
import Wrapper from './components/Wrapper'
import { Error, Warning } from './components/Message'

const GRAPHQL_ERROR = 'GraphQL error: '
const NETWORK_ERROR = 'Network error: '

const Link = styled(unstyledLink)`
  ${link};
  ${fluidFont(16, 16)};
  margin-top: 30px;
  display: inline-block;
`

const Link2 = styled(unstyledLink)`
  ${link};
  ${fluidFont(16, 16)};
`

const Infobox = styled.p`
  color: ${({ theme }) => theme.heading};
  margin-top: 30px;
  margin-bottom: 10px;
  ${fluidFont(16, 16)};

`

const getMessage = children => {
  if (typeof children !== 'string') {
    return children
  }

  if (children.startsWith(GRAPHQL_ERROR)) {
    return children.slice(GRAPHQL_ERROR.length)
  } else if (children.startsWith(NETWORK_ERROR)) {
    return children.slice(NETWORK_ERROR.length)
  }
}

const LoginOrRegister = ({
  register: isRegister,
  usernamePlaceholder,
  emailPlaceholder,
  passwordPlaceholder,
  registerLink,
  loginLink,
  onSubmit,
  error,
  warning,
  ...restProps
}) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')

  const [usernameError, setUsernameError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [emailError, setEmailError] = useState('')

  const validateEmail = () => {
    // don't show a message when the email is empty
    // this is handled by the validateInput function
    if (email === '') {
      setEmailError('')
      return
    }

    const isValid = isEmail(email)

    if (!isValid) {
      setEmailError('Not a valid email address.')
    } else {
      setEmailError('')
    }

    return isValid
  }

  const validateUserInput = () => {
    const hasUsername = username !== ''
    if (hasUsername) {
      setUsernameError('')
    } else {
      setUsernameError('Must provide a username.')
    }

    const hasPassword = password !== ''
    if (hasPassword) {
      setPasswordError('')
    } else {
      setPasswordError('Must provide a password.')
    }

    if (isRegister && email === '') {
      setEmailError('Must provide an email address.')
      return false
    } else {
      setEmailError('')
    }

    // Only need to validate the email when the user is registering
    const hasValidEmail = !isRegister || validateEmail(email)

    return hasUsername && hasPassword && hasValidEmail
  }

  const submit = () => {
    if (validateUserInput()) {
      onSubmit({
        username,
        password,
        email: normalizeEmail(email),
      })
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    submit()
  }

  const handleSubmitOnEnter = e => {
    // Listen for the "Enter" key
    if (e.charCode === 13) {
      submit()
    }
  }

  return (
    <Wrapper {...restProps}>
      <TitleRow
        register={isRegister}
        loginLink={loginLink}
        registerLink={registerLink}
        css="margin-bottom: 20px;"
      />
      <form>
        <div css="margin-bottom: 15px;">
          <TextInput
            type="username"
            placeholder={usernamePlaceholder}
            value={username}
            autoComplete="username"
            required
            onChange={e => setUsername(e.target.value)}
          />
          {usernameError && <Error>{usernameError}</Error>}
        </div>
        {isRegister && (
          <div css="margin-bottom: 15px;">
            <TextInput
              type="email"
              placeholder={emailPlaceholder}
              value={email}
              required
              onChange={e => setEmail(e.target.value)}
              onBlur={validateEmail}
            />
            {emailError && <Error>{emailError}</Error>}
          </div>
        )}
        <div css="margin-bottom: 30px;">
          <TextInput
            type="password"
            placeholder={passwordPlaceholder}
            value={password}
            required
            onChange={e => setPassword(e.target.value)}
            onKeyPress={handleSubmitOnEnter}
            autoComplete={isRegister ? 'new-password' : 'current-password'}
          />
          {passwordError && <Error>{passwordError}</Error>}
        </div>
        <div>
          <Submit
            as="input"
            type="submit"
            onClick={handleSubmit}
            value={isRegister ? 'Register' : 'Login'}
          />
          {error && <Error css="margin-top: 8px;">{getMessage(error)}</Error>}
          {warning && <Warning css="margin-bottom: -10px;">{warning}</Warning>}
        </div>
        {isRegister && (<Infobox>By registering you agree to our<br/><Link2 to="/terms">Terms and conditions</Link2>.</Infobox>)}
      </form>
      {!isRegister && <Link to="/reset-password">Forgot your password?</Link>}
    </Wrapper>
  )
}

LoginOrRegister.defaultProps = {
  register: false,
  usernamePlaceholder: 'Username',
  emailPlaceholder: 'Email',
  passwordPlaceholder: 'Password',
  error: null,
  warning: null,
}

export default LoginOrRegister
