import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { transparentize as fade, ellipsis } from 'polished'
import { up } from 'styled-breakpoints'

import useSubmission from './useSubmission'
import { contentWrapper, link, fluidFont } from '../styles'
import { getProblemUrl } from '../utils/url'
import Left from '../icons/Chevron/left'
import Doc from '../icons/Doc'
import Loading from '../icons/Loading'
import WarningIcon from '../icons/Exclamationmark/triangle/fill'
import ErrorIcon from '../icons/Exclamationmark/circle/fill'
import CheckmarkIcon from '../icons/Checkmark/circle/fill'
import { Table, TableHeader, TableCell } from '../shared/table'
import Error from '../shared/error'
import { formatScore } from '../utils/strings'

const FileFeedbackWrapper = styled.div`
  background-color: ${({ theme }) => theme.surface.regular};
  padding: 20px;
  border-radius: 14px;
  margin-bottom: 30px;

  :last-child {
    margin-bottom: 0px;
  }

  ${up('sm')} {
    padding: 30px 50px;
  }

  ${up('md')} {
    padding: 50px 80px;
    margin-bottom: 50px;
  }
`

const StyledTableCell = styled(TableCell)`
    white-space: normal;
    word-break: normal;
`

const TableWrapper = styled(Table)`
  margin-top: 20px;

  & + & {
    margin-top: 30px;
  }
`

const BackLink = styled(Link)`
  ${link};
  ${fluidFont(18, 20)};
`

const EllipsisLink = styled(Link)`
  ${link};
  ${fluidFont(18, 18)};
  ${ellipsis(350)}
  padding: 1px 0;
`

const Filename = styled.h2`
  margin: 0;
  ${fluidFont(18, 20)};
  font-weight: 500;
  color: ${({ theme }) => theme.title};
  ${ellipsis('100%')};
  padding: 1px 0;
`

const Successful = styled.span`
  ${fluidFont(17, 18)};
  font-weight: 600;
  white-space: nowrap;
  color: ${({ theme }) => fade(0.4, theme.title)};
`

const ErrorMessage = styled.p`
  margin: 0;
  ${fluidFont(17, 18)};
  font-weight: 400;
  line-height: 1.4;
  color: ${({ theme }) => fade(0.2, theme.title)};
`

const StatusIndicator = styled(Loading).attrs(({ status }) => {
  if (status === 'loading') return { as: Loading }
  if (status === 'failure') return { as: ErrorIcon }
  if (status === 'warning') return { as: WarningIcon }
  if (status === 'success') return { as: CheckmarkIcon }
})`
  ${fluidFont(20, 22)};
  margin-right: 4px;
  margin-left: 8px;
  color: ${({ theme, status }) => {
    if (status === 'loading') return theme.title
    if (status === 'failure') return theme.red
    if (status === 'warning') return theme.orange
    if (status === 'success') return theme.green
  }};
`

const getStatus = (status, submissions, errors) => {
  if (status !== 'success') return status
  if (!submissions) return 'failure'
  if (submissions && errors) return 'warning'
  if (submissions && !errors) return 'success'
}

const FileFeedback = ({ id, status, results }) => {
  const addIndex = (val, index) => ({ ...val, index })
  const resultsWithIndex = (results || []).map(addIndex)

  const filterSolutions = res => res.__typename === 'SolutionType'
  const submissions = resultsWithIndex.filter(filterSolutions)

  const filerErrors = res => res.__typename === 'ErrorType'
  const errors = resultsWithIndex.filter(filerErrors)

  const refinedStatus = getStatus(status, submissions.length, errors.length)

  return (
    <FileFeedbackWrapper>
      <div css="display: flex; align-items: baseline;">
        <Filename>
          <Doc css={fluidFont(20, 22)} /> {id}
        </Filename>
        <div css="flex-grow: 1; min-width: 15px;" />
        <Successful>
          {status === 'success' && `${submissions.length} submitted`}
          <StatusIndicator status={refinedStatus} />
        </Successful>
      </div>
      {status === 'failure' && (
        <ErrorMessage css="margin-top: 20px;">
          This file does not follow the correct file format. Read about the
          expected file format in the expansion panels on the{' '}
          <Link css={css`${link}
  ${fluidFont(18, 18)};

          `} to="/submit">
            submit page
          </Link>
          .
        </ErrorMessage>
      )}
      {status === 'success' && (
        <>
          {submissions.length > 0 && (
            <TableWrapper columns="auto 1fr 1fr 1fr">
              <TableHeader>Index</TableHeader>
              <TableHeader>Problem</TableHeader>
              <TableHeader>Score</TableHeader>
              <TableHeader>Rank</TableHeader>
              {submissions.map(({ index, score, id, problem, rank }) => (
                <React.Fragment key={id}>
                  <TableCell>{index}.</TableCell>
                  <TableCell>
                    <EllipsisLink
                      to={getProblemUrl(problem.challenge.id, problem.id)}
                    >
                      {problem.challenge.name}: {problem.name}
                    </EllipsisLink>
                  </TableCell>
                  <TableCell>{formatScore(score)}</TableCell>
                  <TableCell>{rank}</TableCell>
                </React.Fragment>
              ))}
            </TableWrapper>
          )}
          {errors.length > 0 && (
            <TableWrapper columns="auto 1fr">
              <TableHeader>Index</TableHeader>
              <TableHeader>Error</TableHeader>
              {errors.map(({ message, index }) => (
                <React.Fragment key={index}>
                  <StyledTableCell>{index}.</StyledTableCell>
                  <StyledTableCell>{message}</StyledTableCell>
                </React.Fragment>
              ))}
            </TableWrapper>
          )}
        </>
      )}
    </FileFeedbackWrapper>
  )
}

const Result = () => {
  const state = useSubmission()

  const filesState = Object.values(state)

  if (!filesState.length) {
    return <Error css="margin-top: 210px">No file submitted</Error>
  }

  return (
    <div css={contentWrapper}>
      <div css="margin: 30px 0;">
        <BackLink to="/submit">
          <Left /> Submit more
        </BackLink>
      </div>
      {filesState.map(({ id, ...rest }) => (
        <FileFeedback key={id} id={id} {...rest} />
      ))}
    </div>
  )
}

export default Result
