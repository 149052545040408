import React from 'react'
import { Link } from 'react-router-dom'
import { link } from '../styles'
import styled from 'styled-components'
import { contentWrapper } from '../styles'
import MainHeading from '../shared/mainheading'
import SectionHeading from '../shared/sectionheading'
import SubHeading from '../shared/subheading'
import Paragraph from '../shared/paragraph'

const Wrapper = styled.div`
  ${contentWrapper};
  margin-top: 10vh;
`

const List = styled.ul`
  padding-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
`

const StyledLink = styled(Link)`
  ${link};
`

const Privacy = () => {
  return (
    <Wrapper>
      <MainHeading>Privacy Policy</MainHeading>
      <Paragraph>Effective Date: 14th January 2021 </Paragraph>
      <SectionHeading>Summary</SectionHeading>
      <Paragraph>Welcome and thank you for your interest in ESA's platform "Optimize" and its services. This Privacy Policy is meant to help you understand what information we collect, why we collect it and how you can update, manage and delete your information.</Paragraph>
      
      <Paragraph>If you decide to register and take part at the challenges here on Optimize, there will be two types of data that we collect: Personal data and content. The minimum personal data needed for this service to work is your account name, your email address and a password. The purpose of collecting this data is the generation of your user account, authentication and the option to contact you.</Paragraph>
      
      <Paragraph>Your email will not be shared to anyone and never shown publicly. Your password is stored encrypted and hashed. You may voluntarily add personal information for public display on your user profile, but this is not necessary for any services of this platform to work. Be mindful about what you add to your profile, as this information is public and viewable for anyone on the internet.</Paragraph>
      
      <Paragraph>If you decide to take part in our competitions (and we hope you do!) you will be able to submit solution files. The content of those solution files are not public, but are used to rank your account in our leaderboards. Thus, if your solutions are valid, a score will be associated with your username and you will be ranked among other users on the leaderboards of this platform.</Paragraph>
      
      <Paragraph>You may delete your account at any point you wish by using the corresponding function in your user settings. This will remove all personal information from our servers. The content that you submitted (i.e. the entries of our leaderboards) will however remain. Thus, by submitting a solution file to our platform you agree that all its content will be stored and utilized by ESA.</Paragraph>
      
      <Paragraph>ESA is an intergovernmental organisation dedicated to the peaceful exploration of space. We hope that Optimize can generate new scientific insights that will help us to work towards this goal. Thus, our interest is purely academic in nature. In other words: we are no company that is making money with your personal data. We want you to feel safe in using our services and value your privacy. ESA does not consider your personal data as an asset for sale and, thus, does not sell your personal data to any third parties. We will not disclose any of your personal data.</Paragraph>
      
      <Paragraph>This should summarize the keypoints, but in the following we provide more detailed information to be compliant with best data protection policies as established by the EU. If you have still questions about our Privacy Policy, feel free to contact us!</Paragraph>
      
      <SubHeading>1. Who is the Data Controller and the Data Protection Officer?</SubHeading>
      <Paragraph>The Advanced Concepts Team of the European Space Agency is the sole Data Controller. You may contact our Data Protection Officer by contacting the Advanced Concepts Team by email to act"at"esa.int.</Paragraph>

      <SubHeading>2. What kind of personal data about you are collected and further processed?</SubHeading>
      <Paragraph>Upon registration we collect your username and your eMail address. You may provide additional personal information in your public user profile such as your Country, Affiliation or Website.      
      </Paragraph>
      
      <SubHeading>3. How are your personal data collected or further processed?</SubHeading>
      <Paragraph>Your personal data are collected during registration and by direct interaction with the website, for example by 
      explicitly editing your user Profile.</Paragraph>      
   
      <SubHeading>4. Why are your personal data collected or further processed?</SubHeading>
      <Paragraph>Your personal data are collected and further processed in order to 
      <List>
      <li>Provide you access to and enable the use of the website.</li>
      <li>Enable your participation to the challenges posted on Optimize.</li>
      <li>Enable us to contact you via eMail if the need arises (e.g. for password resets).</li>
      </List>
      The organizers of this platform may also send you messages based on your activities on our services, for example your
      interaction with particular challenges.
      
      </Paragraph>

      <SubHeading>5. To whom might we disclose your personal data?</SubHeading>
      <Paragraph>We do not disclose your personal data without your explicit consent to any third parties. You may share personal information through our services if you decide to put them into your public profile.</Paragraph>

      <SubHeading>6. How long do we retain your personal data for?</SubHeading>
      <Paragraph>We retain your personal data as long as we provide Optimize as a service.</Paragraph>

      <SubHeading>7. How can you erase, rectify, complete or amend your personal data?</SubHeading>
      <Paragraph>You can access your public profile using <StyledLink to="/me">https://optimize.esa.int/me</StyledLink> or by clicking on your username in the top navigation bar. A further click on "Edit Profile" will allow you to change your personal information or irrevocably erase your complete account. Content in form of submission files might remain anonymized on the platform. Instead of your username, a randomly generated pseudonym will take its place in the corresponding leaderboards.</Paragraph>
      
      <SubHeading>8. What could you do in case of a data protection incident?</SubHeading>
      <Paragraph>You should contact us by sending an eMail to act"at"esa.int. In case you wish to submit a complaint, you will be required to demonstrate that a data protection incident occured in relation to your personal data, following a decision of the organizers of this platform or at least to justify serious reasons to believe that such incident occurred.</Paragraph>
      
      <SubHeading>9. Your Consent</SubHeading>
      <Paragraph>For those cases where your consent was not already obtained by ESA (including by other modalities) and is required under the ESA Framework on Personal Data Protection, you agree with the collection and further processing of your personal data.
      You will be able to withdraw your consent depending on the modality used to collect your personal data by erasing your account (please note that content like your submissions remain anonymized on the platform without a possibility to recover the
connection at a later stage or by a new registration).</Paragraph>
      
    </Wrapper>
  )
}

export default Privacy