import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'

import { authLink } from './Auth/Provider'
import introspectionQueryResultData from './fragmentTypes.json'

const API_URL = process.env.REACT_APP_API_URL

const httpLink = new HttpLink({
  uri: API_URL + '/graphql/',
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>{
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}`
      )
      return null}
    )
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

const link = ApolloLink.from([authLink, errorLink, httpLink])

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

const cache = new InMemoryCache({
  fragmentMatcher,
  cacheRedirects: {
    Query: {
      problem: (_, args, { getCacheKey }) =>
        getCacheKey({ __typename: 'ProblemType', id: args.id }),
      challenge: (_, args, { getCacheKey }) =>
        getCacheKey({ __typename: 'ChallengeType', id: args.id }),
      user: (_, args, { getCacheKey }) =>
        getCacheKey({ __typename: 'UserType', id: args.id }),
    },
    ChallengeType: {
      problem: (_, args, { getCacheKey }) =>
        getCacheKey({ __typename: 'ProblemType', id: args.id }),
    },
  },
})

const client = new ApolloClient({
  link,
  cache,
})

export default client
