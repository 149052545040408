import styled from 'styled-components'

import { fluidFont } from '../styles'

export default styled.h2`
  font-weight: 500;
  margin: 20px 0 20px 0;
  color: ${({ theme }) => theme.title};  
  ${fluidFont(26, 38)};
  line-height: 1.4;
`