import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

import Item from './Item'
import Logo from './Logo'
import USER from './User.gql'
import { getUserUrl } from '../utils/url'
import { useCurrentUserId } from '../Auth/hooks'
import User from '../User/loadable'
import Challengehub from '../Challengehub/loadable'
import Submission from '../Submission/loadable'
import Home from '../Home/loadable'

const Scroll = styled.div`
  overflow: auto;
  z-index: 9999;
  position: relative;

  ::-webkit-scrollbar {
    display: none;
  }
`

const Nav = styled.nav`
  width: 100%;
  max-width: ${({ theme }) => theme.contentWidth + 2 * 20}px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;

  ${up('sm')} {
    padding-left: 50px;
    max-width: ${({ theme }) => theme.contentWidth + 2 * 50}px;
  }

  ${up('md')} {
    padding-left: 130px;
    max-width: ${({ theme }) => theme.contentWidth + 2 * 130}px;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
`

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  padding-right: 20px;

  ${up('sm')} {
    padding-right: 50px;
  }

  ${up('md')} {
    padding-right: 130px;
  }
`

const Username = ({ userId }) => {
  const { loading, data, error } = useQuery(USER, {
    variables: { id: userId },
  })

  if (loading || error) return 'Account'

  return (data && data.user.username) || 'Account'
}

const Navigation = ({ children, ...restProps }) => {
  const currentUserId = useCurrentUserId()

  return (
    <Scroll>
      <Nav {...restProps}>
        <Logo to="/" onMouseEnter={Home.preload} />
        <div css="flex-grow: 1;" />
        <ItemWrapper>
          <Item to="/challenges" onMouseEnter={Challengehub.preload}>
            Challenges
          </Item>
          <Item to="/submit" onMouseEnter={Submission.preload}>
            Submit
          </Item>
          <Item
            to={currentUserId ? getUserUrl(currentUserId) : '/register'}
            onMouseEnter={currentUserId ? User.preload : undefined}
          >
            {currentUserId ? <Username userId={currentUserId} /> : 'Account'}
          </Item>
        </ItemWrapper>
      </Nav>
    </Scroll>
  )
}

export default Navigation
