import { transparentize as fade } from 'polished'

export const grays = {
  '000': '#FFFFFF',
  '100': '#F2F2F2',
  '200': '#D8D8D8',
  '300': '#8C8C8C',
  '400': '#666666',
  '500': '#4A4A4A',
  '600': '#363636',
  '700': '#262626',
  '800': '#131313',
  '900': '#000000',
}

export const DAY = {
  red: '#FF3B30',
  orange: '#FF9500',
  yellow: '#FFCC00',
  green: '#4CD964',
  blue: '#007AFF',
  purple: '#5856D6',
  pink: '#FF2D55',
  background: grays['000'],
  transparent: 'rgba(255, 255, 255, 0)',
  semitransparent: 'rgba(255, 255, 255, 0.6)',
  border: {
    input: fade(0.94, grays['900']),
    content: fade(0.9, grays['900']),
    divider: grays['200'],
    dividerOpacity: fade(0.85, grays['900']),
  },
  heading: grays['800'],
  headingSubtle: grays['600'],
  primary: '#007AFF',
  surface: {
    opacity: fade(0.96, grays['900']),
    regular: grays['100'],
    selected: grays['200'],
  },
  text: {
    regular: grays['700'],
    bold: grays['900'],
    subtle: grays['400'],
  },
  title: grays['900'],
  id: 'DAY',
}

export const NIGHT = {
  red: '#FF5E5E',
  orange: '#FFAA55',
  yellow: '#FFEE66',
  green: '#68D980',
  blue: '#66BBFF',
  purple: '#837BDB',
  pink: '#FF5577',
  background: grays['800'],
  transparent: 'rgba(255, 255, 255, 0)',
  semitransparent: 'rgba(255, 255, 255, 0.6)',  
  border: {
    input: fade(0.94, grays['000']),
    content: fade(0.9, grays['000']),
    divider: grays['500'],
    dividerOpacity: fade(0.85, grays['000']),
  },
  heading: grays['100'],
  headingSubtle: grays['600'],
  primary: '#66BBFF',
  surface: {
    opacity: fade(0.94, grays['000']),
    regular: grays['700'],
    selected: grays['600'],
  },
  text: {
    regular: grays['200'],
    bold: grays['000'],
    subtle: grays['300'],
  },
  title: grays['000'],
  id: 'NIGHT',
}
