export const USER_URL = '/user/:userId'
export const getUserUrl = userId => `/user/${userId}`

export const PROBLEM_URL = '/challenge/:challengeId/p/:problemId'
export const CHALLENGE_URL = '/challenge/:challengeId/:tabId'

export const getProblemUrl = (challengeId, problemId) =>
  `/challenge/${challengeId}/p/${problemId}`
  
export const getChallengeUrl = (challengeId, tabId) => {
  return tabId ? `/challenge/${challengeId}/${tabId}` : `/challenge/${challengeId}/About`
}
  
export const CHALLENGEHUB_URL = '/challenges'
export const TOOLS_URL = process.env.REACT_APP_API_URL + '/data/tools/submission_helper.py'
export const REGISTER_URL = '/register'
export const SUBMIT_URL = '/submit'
export const TERMS_URL = '/terms'
export const PRIVACY_URL = '/privacy'
export const SECRET_URL = '/secret'