export const capitalize = string => {
  if (typeof string !== 'string') return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const locale = 'en-US'

const numberFormat = new Intl.NumberFormat(locale, {
  style: 'decimal',
  maximumFractionDigits: 3,
})

const dateFormat = new Intl.DateTimeFormat(locale, {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
})

export const formatDate = date => {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  return dateFormat.format(date)
}

export const formatScore = numberFormat.format
