import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/react-hooks'

import Theme from './styles/Theme'
import Routes from './shared/routes'
import * as serviceWorker from './serviceWorker'
import client from './apollo'
import AuthProvider from './Auth/Provider'

const MAINTENANCE = process.env.REACT_APP_MAINTENANCE
const maintenance = MAINTENANCE ? MAINTENANCE.toLowerCase() === "maintenance" : false

const Root = (
  <ApolloProvider client={client}>
    <Router>
      <Theme>
        <AuthProvider>
          <Routes maintenance={maintenance}/>
        </AuthProvider>
      </Theme>
    </Router>
  </ApolloProvider>
)

ReactDOM.render(Root, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()