import React, { useState } from 'react'
import isEmail from 'validator/lib/isEmail'
import { useMutation } from '@apollo/react-hooks'
import { Redirect } from 'react-router-dom'

import Wrapper from './components/Wrapper'
import Title from './components/Title'
import TextInput from '../shared/text-input'
import Submit from '../shared/button-input'
import { Error } from './components/Message'
import REQUEST_PASSWORD_RESET from './queries/RequestPasswordReset.gql'
import Loading from '../shared/loading'
import Message from '../shared/message'
import { useCurrentUserId } from './hooks'
import { getUserUrl } from '../utils/url'

const RequestPasswordReset = () => {
  const currentUserId = useCurrentUserId()
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')

  const [requestPasswordReset, { loading, data }] = useMutation(
    REQUEST_PASSWORD_RESET
  )

  if (currentUserId) {
    return <Redirect to={getUserUrl(currentUserId)} />
  }

  const validateEmail = () => {
    // don't show a message when the email is empty
    // this is handled by the validateInput function
    if (email === '') {
      setEmailError('')
      return
    }

    const isValid = isEmail(email)

    if (!isValid) {
      setEmailError('Not a valid email address')
    } else {
      setEmailError('')
    }

    return isValid
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (email === '') {
      setEmailError('Provide an email address')
      return
    }

    requestPasswordReset({ variables: { email } })
  }

  if (data && data.requestPasswordReset.success) {
    return (
      <Message title="Request has been send">
        Check your inbox for the link to reset your password.
      </Message>
    )
  }

  return (
    <Wrapper>
      <Title css="margin-bottom: 20px;">Reset password</Title>
      <form>
        <div css="margin-bottom: 30px;">
          <TextInput
            type="email"
            placeholder="Your email address"
            value={email}
            required
            onChange={e => setEmail(e.target.value)}
            onBlur={validateEmail}
          />
          {emailError && <Error>{emailError}</Error>}
        </div>
        <Submit
          as="input"
          type="submit"
          onClick={handleSubmit}
          value="Send reset request"
        />
      </form>
      {loading && <Loading />}
    </Wrapper>
  )
}

export default RequestPasswordReset
