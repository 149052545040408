import React, { useEffect } from 'react'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import {
  CHALLENGEHUB_URL,
  CHALLENGE_URL,
  PROBLEM_URL,
  USER_URL,
  TERMS_URL,
  PRIVACY_URL,
  getUserUrl,
} from '../utils/url'
import LoginOrRegisterHandler, { loginOrRegisterPaths } from '../Auth/Handler'
import Footer from './footer'
import Navigation from '../Navigation'
import Activate from '../Auth/Activate'
import Delete from '../Auth/Delete'
import { useCurrentUserId } from '../Auth/hooks'
import Message from './message'
import Result from '../Submission/Result'
import Terms from '../Legal/Terms'
import Privacy from '../Legal/Privacy'
import User from '../User/loadable'
import Challenge from '../Challenge/loadable'
import Challengehub from '../Challengehub/loadable'
import Problem from '../Problem/loadable'
import Submission from '../Submission/loadable'
import Home from '../Home/loadable'
import Maintenance from '../Home/Maintenance'
import RequestPasswordReset from '../Auth/RequestPasswordReset'
import ResetPassword from '../Auth/ResetPassword'

const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return children || null
}

const App = ({ maintenance}) => {
  const currentUserId = useCurrentUserId()

  if (maintenance) {
    return <Maintenance />
    } else {
    return (
    <>
      <Helmet defaultTitle="Optimize" />
      <ScrollToTop />
      <Navigation />

      <Switch>
        <Route path="/" exact component={Home} />        
        <Route path="/register/:userId/:activationToken" component={Activate} />
        <Route path="/submit/result" component={Result} />
        <Route path="/submit" component={Submission} />
        <Route path={CHALLENGEHUB_URL} component={Challengehub} />        
        <Route path={PROBLEM_URL} component={Problem} />
        <Route path={CHALLENGE_URL} component={Challenge} />        
        <Route path="/goodbye" component={Delete} />
        <Route path="/me">
          {() =>
            currentUserId ? (
              <Redirect to={getUserUrl(currentUserId)} />
            ) : (
              <Redirect to="/login" />
            )
          }
        </Route>
        <Route path={USER_URL} component={User} />
        <Route path={TERMS_URL} component={Terms} />
        <Route path={PRIVACY_URL} component={Privacy} />
        <Route path={loginOrRegisterPaths} component={LoginOrRegisterHandler} />
        <Route
          path="/reset-password/:userId/:resetToken"
          component={ResetPassword}
        />
        <Route path="/reset-password" component={RequestPasswordReset} />
        <Route>
          {() => (
            <Message title="Sorry this page does not exist">
              The requested URL was not found.
              <br />
              Try a different URL or use the navigation.
            </Message>
          )}
        </Route>
      </Switch>

      <div css="flex-grow:1;" />
      <Footer />
    </>
    )}
}

export default App
