import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { transparentize as fade } from 'polished'

import { fluidFont } from '../../styles'

const Title = styled(Link)`
  display: block;
  font-weight: 500;
  ${fluidFont(17, 17)};
  color: ${({ theme }) => theme.primary};
  text-decoration: none;
  transition: color 80ms ease-out;

  :active {
    color: ${({ theme }) => fade(0.5, theme.primary)};
  }
`

export default Title
