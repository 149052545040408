import React from 'react'
import styled from 'styled-components'
import { transparentize as fade } from 'polished'

import Title from '../shared/title'
import { contentWrapper, fluidFont } from '../styles'

const Wrapper = styled.div`
  ${contentWrapper};
  margin-top: 22vh;
`

const Body = styled.p`
  margin: 0;
  padding: 0;
  ${fluidFont(20, 28)};
  font-weight: 600;
  line-height: 1.4;
  color: ${({ theme }) => fade(0.3, theme.title)};
  text-align: center;
  margin-top: 8px;
`

const Message = ({ title, children, ...restProps }) => {
  return (
    <Wrapper {...restProps}>
      <Title>{title}</Title>
      <Body>{children}</Body>
    </Wrapper>
  )
}

export default Message
