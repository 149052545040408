import React from 'react'

import Base from '../../Base'

export default props => (
  <Base {...props} boxWidth={2488}>
    <path
      d="M1213 -313c-566 0 -1034 468 -1034 1034c0 567 467 1035 1033 1035s1035 -468 1035 -1035c0 -566 -468 -1034 -1034 -1034zM1212 579c57 0 90 33 93 93l15 458c3 61 -45 106 -109 106s-110 -44 -108 -105l15 -459c2 -60 35 -93 94 -93zM1212 204c67 0 122 46 122 114
s-55 114 -122 114s-122 -47 -122 -114s56 -114 122 -114z"
    />
  </Base>
)
