import React from 'react'
import Message from '../shared/message'

const Maintenance = () => {
  return (
    <Message title="Maintenance">
      Thank you for your interest in our platform.
      <br />
      Optimize is currently under maintenance and will return soon.
    </Message>
  )
}

export default Maintenance