import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { useActivate } from './hooks'
import Message from '../shared/message'
import Loading from '../shared/loading'
import { link } from '../styles'

const Activate = ({ match }) => {
  const { userId, activationToken } = match.params
  const [activate, { error, loading, success }] = useActivate()

  useEffect(() => {
    activate({ activationToken, userId })
  }, [activate, activationToken, userId])

  if (loading) {
    return <Loading>Activating your account…</Loading>
  }

  if (error || !success) {
    return (
      <Message title="Sorry the activation failed">
        An activation link can only be used once.
        <br />
        Make sure to use the URL provided by email.
      </Message>
    )
  }

  return (
    <Message title="Thank you for registering">
      Your account was successfully activated.
      <br />
      You are now able to{' '}
      <Link
        css={`
          ${link};
          font-size: inherit;
          font-weight: inherit;
        `}
        to="/login"
      >
        login
      </Link>
      .
    </Message>
  )
}

export default Activate
