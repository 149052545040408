import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { up } from 'styled-breakpoints'

import { useTheme } from '../utils/hooks'
import logoWhite from '../images/logo-white.png'
import logoBlack from '../images/logo-black.png'
import { surface } from './styles'

const Surface = styled(Link)`
  ${surface};
  padding: 2px 8px;
  min-width: 100px;
  user-select: none;
  flex: 0 0 auto;
  margin-right: 30px;

  ${up('md')} {
    padding: 2px 15px;
    margin-right: 50px;
  }

  & > img {
    height: 48px;

    ${up('md')} {
      height: 52px;
    }
  }
`

const Logo = props => {
  const theme = useTheme()

  const src = theme.id === 'NIGHT' ? logoWhite : logoBlack

  return (
    <Surface {...props}>
      <img css="display: block;" alt="ESA logo" src={src} />
    </Surface>
  )
}

export default Logo
