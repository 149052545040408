import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { transparentize as fade } from 'polished'

import LoadingIcon from '../icons/Loading'
import { contentWrapper, fluidFont } from '../styles'

const Wrapper = styled.div`
  ${contentWrapper};
  ${fluidFont(22, 22)};
  text-align: center;
  margin: 50px auto;
  color: ${({ theme }) => theme.title};
`

const Body = styled.p`
  margin: 0;
  padding: 0;
  ${fluidFont(24, 24)};
  font-weight: 600;
  margin-top: 30px;
  color: ${({ theme }) => fade(0.3, theme.title)};
`

// delay avoids flashing the loading component
// when the content loads quick.
const Loading = ({ delay, children, ...restProps }) => {
  const [waited, setWaited] = useState(!delay)

  useEffect(() => {
    if (delay) {
      const tick = () => {
        setWaited(true)
      }

      const timeout = setTimeout(tick, delay)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [delay])

  return (
    <Wrapper {...restProps}>
      <div>{waited && <LoadingIcon />}</div>
      {children && waited && <Body>{children}</Body>}
    </Wrapper>
  )
}

Loading.defaultProps = {
  delay: 200,
}

export default Loading
