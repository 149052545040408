import styled from 'styled-components'
import { up } from 'styled-breakpoints'

const WIDTH = 380

const Wrapper = styled.div`
  width: 100%;
  max-width: ${WIDTH + 2 * 20}px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 50px;
  text-align: center;

  ${up('sm')} {
    margin-top: 80px;
    padding-left: 50px;
    padding-right: 50px;
    max-width: ${WIDTH + 2 * 50}px;
  }

  ${up('md')} {
    margin-top: 130px;
    padding-left: 130px;
    padding-right: 130px;
    max-width: ${WIDTH + 2 * 130}px;
  }
`

export default Wrapper
