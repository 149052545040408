import React from 'react'
import { ThemeProvider } from 'styled-components'

import { NIGHT, DAY } from './colors'
import { breakpoints } from './breakpoints'
import { useMediaQuery } from '../utils/hooks'
import GlobalStyle from './global'
import { Helmet } from 'react-helmet'

const Theme = ({ children }) => {
  const wantsLightTheme = useMediaQuery('(prefers-color-scheme: light)')

  const theme = {
    ...(wantsLightTheme ? DAY : NIGHT),
    breakpoints,
    contentWidth: 828,
    navigationHeight: 86,
  }

  return (
    <ThemeProvider theme={theme}>
      <>
        <Helmet>
          {/* Correct active css styles in iOS */}
          <body ontouchstart="" />
        </Helmet>
        <GlobalStyle />
        {children}
      </>
    </ThemeProvider>
  )
}

export default Theme
