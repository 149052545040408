import styled from 'styled-components'

import { fluidFont } from '../styles'

export default styled.h1`
  margin: 0;
  font-weight: 700;
  color: ${({ theme }) => theme.title};
  text-align: center;
  line-height: 1.25;
  ${fluidFont(32, 46)};
`
