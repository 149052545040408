import styled from 'styled-components'

import { fluidFont } from '../../styles'

export const Message = styled.p`
  ${fluidFont(14, 14)};
  font-weight: 500;
  margin: 0;
  margin-top: 4px;
  margin-left: 4px;
  line-height: 1.4;
  text-align: left;
`

export const Warning = styled(Message)`
  color: ${({ theme }) => theme.orange};
`

export const Error = styled(Message)`
  color: ${({ theme }) => theme.red};
`

export default Message
