import { useEffect, useReducer } from 'react'
import { useMutation, useApolloClient } from '@apollo/react-hooks'
import { useLocation, useHistory } from 'react-router-dom'

import { readJSONFile } from '../utils/files'
import SUBMIT_SOLUTIONS from './SubmitSolutions.gql'

const init = (files = []) => {
  const state = files.reduce((state, file) => {
    state[file.name] = { status: 'loading', id: file.name }
    return state
  }, {})

  return state
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'failure':
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          status: 'failure',
          error: action.message,
        },
      }
    case 'success':
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          status: 'success',
          results: action.results,
        },
      }
    default:
      throw new Error()
  }
}

const useSubmission = () => {
  const location = useLocation()
  const history = useHistory()
  const files = location.state && location.state.files
  const [state, dispatch] = useReducer(reducer, files, init)
  const [submit] = useMutation(SUBMIT_SOLUTIONS)
  const client = useApolloClient()

  useEffect(() => {
    const submitFiles = async () => {
      const submissionPromises = files.map(async file => {
        try {
          const fileData = await readJSONFile(file)
          const solutions = Array.isArray(fileData) ? fileData : [fileData]

          const results = await submit({ variables: { solutions } })

          dispatch({
            type: 'success',
            id: file.name,
            results: results.data.submitSolutions.solutions,
          })
        } catch (error) {
          dispatch({ type: 'failure', id: file.name, message: error.message })
        }
      })

      await Promise.all(submissionPromises)
      await client.resetStore()
    }

    if (files) submitFiles()
  }, [client, files, submit])

  useEffect(() => {
    if (files) {
      const values = Object.values(state)
      const isLoading = values.filter(val => val.status === 'loading')
      const isDone = isLoading.length === 0

      if (isDone) {
        history.replace(location.pathname, { result: state })
      }
    }
  })

  if (!files && location.state && location.state.result) {
    return location.state.result
  }

  return state
}

export default useSubmission
