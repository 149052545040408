import styled, { css } from 'styled-components'
import { transparentize as fade } from 'polished'

import {
  contentWrapper,
  surface as backdropSurface,
  fluidFont,
} from '../styles'

export const surface = css`
  ${backdropSurface};
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
  transition: color 80ms ease-out;
  pointer-events: ${({ active }) => (active ? 'none' : 'inherit')};
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  color: ${({ theme, active }) =>
    active ? fade(0.5, theme.title) : theme.title};

  &:active {
    color: ${({ theme }) => fade(0.5, theme.title)};
  }
`

export const Wrapper = styled.nav`
  ${contentWrapper};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  z-index: 9999;
`

export const Title = styled.h2`
  ${fluidFont(20, 20)};
  font-weight: 600;
  margin: 0;
  color: ${({ theme }) => theme.title};
`
