import styled from 'styled-components'
import { transparentize as fade } from 'polished'

import { fluidFont } from '../styles'

const TextInput = styled.input`
  font-family: var(--font-modern);
  appearance: none;
  background-color: transparent;
  outline: none;
  transition: color 80ms ease-out;
  text-decoration: none;
  margin: 0;
  display: block;
  width: 100%;
  ${fluidFont(17, 17)};
  font-weight: 400;
  padding: 12px 15px;
  border-radius: 10px;
  color: ${({ theme }) => theme.title};
  border: 2px solid ${({ theme }) => theme.border.input};

  :disabled {
    color: ${({ theme }) => fade(0.5, theme.title)};
    background-color: ${({ theme }) => theme.surface.regular};
  }

  ::placeholder {
    color: ${({ theme }) => fade(0.5, theme.title)};
  }
`

TextInput.defaultProps = {
  type: 'text',
}

export default TextInput
