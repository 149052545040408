import styled from 'styled-components'

import { link, fluidFont } from '../styles'

const ButtonInput = styled.button`
  ${link};
  display: block;
  text-align: center;
  width: 100%;
  ${fluidFont(17, 17)};
  padding: 12px 15px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.surface.regular};
  color: ${({ theme }) => theme.primary};
  border: none;
`

export default ButtonInput
