import React from 'react'
import { Link } from 'react-router-dom'
import { link } from '../styles'
import styled from 'styled-components'
import { contentWrapper } from '../styles'
import MainHeading from '../shared/mainheading'
import SectionHeading from '../shared/sectionheading'
//import SubHeading from '../shared/subheading'
import Paragraph from '../shared/paragraph'

const Wrapper = styled.div`
  ${contentWrapper};
  margin-top: 10vh;
`

const List = styled.ul`
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
`

const StyledLink = styled(Link)`
  ${link};
`


const Terms = () => {
  return (
    <Wrapper>
      <MainHeading>Terms and Conditions</MainHeading>
      <Paragraph>Effective Date: 14th January 2021 </Paragraph>


<Paragraph>These terms and conditions outline the rules and regulations for the use of the Optimize web platform, located at <StyledLink to='/'>https://optimize.esa.int</StyledLink>.</Paragraph>

<Paragraph>By accessing this Website we assume you accept these terms and conditions. Do not continue to use Optimize if you do not agree to take all of the terms and conditions stated on this page.</Paragraph>

<SectionHeading>Summary</SectionHeading>
<Paragraph>This summary does not free you from acknowledging the full wall of text below, but we thought to give you the most important bits in plain English to safe you some pain:

<List>
<li>We use cookies and save your eMail so Optimize can work. We have a purely academic interest in Optimize and are not in the business of tracking you or selling your data, so you can feel safe!</li>
<li>We do our best to test the code on this site, but if you decide to download and run it: you are responsible for everything that happens because of it.</li>
<li>We do not spam you and you do not spam us.</li>
<li>You register and use not more than one account!</li>
<li>You play fair when you participate in challenges and respect the community on this platform.</li>
<li>You may spread the word and link to us, but do not depict our platform in a bad light. Should you have any feedback to us, simply let us know directly!</li>
</List>

</Paragraph>


<SectionHeading>Definitions</SectionHeading>

<Paragraph>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: 
<List>
<li>"User", "You" and "Your" refers to you, the person log on this Website and compliant to the our terms and conditions.</li>
<li>"ESA", "Ourselves", "We", "Our", "Organizers" and "Us", refers to the European Space Agency.</li>
<li>"Optimize" refers to this web platform and it's corresponding services.</li>
</List>
Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to the same.</Paragraph>

<SectionHeading>Cookies</SectionHeading>

<Paragraph>We employ the use of cookies in form of web tokens. By accessing Optimize, you agreed to use cookies in agreement with our Privacy Policy. 
Like many other interactive Websites, we use cookies to retrieve the user’s details for each visit. This enables us to provide secure authentification on our web platform. Our cookies are not used to facilitate any form of advertisement.</Paragraph>


<SectionHeading>License</SectionHeading>

<Paragraph>Unless otherwise stated, ESA owns the intellectual property rights for all material on Optimize. All intellectual property rights are reserved. You may access material from Optimize for your own personal use subjected to restrictions set in these terms and conditions.</Paragraph>

<Paragraph>You must not:
<List>
    <li>Republish material from Optimize</li>
    <li>Sell, rent or sub-license material from Optimize</li>
    <li>Reproduce, duplicate or copy material from Optimize</li>
    <li>Redistribute content from Optimize</li>
</List>
</Paragraph>

<Paragraph>Certain parts of Optimize allow users to publish personal content. We reserve the right to monitor all content and to remove any content which can be considered inappropriate, offensive or causes a breach of these Terms and Conditions.</Paragraph>

<SectionHeading>Challenges</SectionHeading>

<Paragraph>Optimize provides you a platform of computational challenges on which you can participate by making submissions. We do not ensure that the content of these challenges is correct or complete in any way. Furthermore we do not guarantee the availability of challenges and may add and remove them at our own discretion.</Paragraph>

<SectionHeading>Code of Honor</SectionHeading>

<Paragraph> By registering to our platform, you agree to the following code of honor:

<List>
<li>Only one single account per user is permitted. Submission from multiple accounts are not allowed.</li>
<li>Any form of cheating challenges is considered a threat against the Optimize community and will be treated as such.</li>
<li>All users shall respect their fellow competitors and not engage in any activity leading to any form of harm to others.</li>
</List>

Not following these rules will have consequences such as removal of submissions, banning and the deletion of user accounts without further notice.</Paragraph>

<SectionHeading>Your Privacy</SectionHeading>

<Paragraph>Please refer to our <StyledLink to='/privacy'>Privacy Policy</StyledLink>.</Paragraph>

<SectionHeading>Code and Software</SectionHeading>

<Paragraph>Optimize provides you downloadable software in form of Python scripts. This code is distributed and subject to the license condition of the <StyledLink to="http://mozilla.org/MPL/2.0/">Mozilla Public License v. 2.0</StyledLink>.

We will not be responsible for any damage that results in the execution of any code obtained from Optimize. Any form of usage and execution of this code is at your discretion only and we give no warranty in any form or sense.</Paragraph>

<SectionHeading>Submissions</SectionHeading>

<Paragraph>
You may freely submit your solutions to the challenges published on optimize, as long as you are not impacting our services negatively, e.g. by flooding our leaderboards or overloading our servers with unreasonable amounts of tasks. In case you abuse our services, we might remove your account, including all or some of your submissions, and provide measures to block you from all our services. By submitting, you agree that a score based on the submission will be associated with you and that your username appears ranked on our public leaderboards. If you decide to remove your account, your username will be substituted with a randomly generated pseudonym and your score and your submission files will be associated with it. Thus, you agree that we retain all submission files once you uploaded them to our service. Submission files are not considered personal data according to our <StyledLink to='/privacy'>Privacy Policy</StyledLink> and we are not obliged to remove them, although we retain the right to do so.</Paragraph>

<SectionHeading>Hyperlinking to our Content</SectionHeading>

<Paragraph>You may freely link to our home page or any publication or material as long as the link

<List>
<li>is not in any way deceptive</li>
<li>does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services</li>
<li>fits within the context of the linking party’s site; and</li>
<li>will not make us look unfavourable.</li>
</List>

We withhold the right to revoke this permission from you, should any of the above conditions be violated.</Paragraph>

<SectionHeading>Removal of StyledLinks</SectionHeading>

<Paragraph>We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amend these terms and conditions and it’s linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</Paragraph>

<Paragraph>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or to respond to you directly.</Paragraph>


<SectionHeading>iFrames</SectionHeading>

<Paragraph>Without prior approval, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</Paragraph>

<SectionHeading>Content Liability</SectionHeading>

<Paragraph>We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</Paragraph>

<Paragraph>We do not ensure that the information on this Website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the Website remains available or that the material on the Website is kept up to date.</Paragraph>

<SectionHeading>Compliance with Laws and Regulations</SectionHeading>
<Paragraph>All Users must observe all local, regional, national, and international laws, orders, directives, ordinances, treaties, rules and regulations which may apply to Users
in connection with all aspects of the registration to, and use of, Optimize. Users are solely responsible for acquiring the appropriate licenses, waivers, or permits under the applicable regulatory bodies or other applicable third parties. ESA shall in no way be responsible for providing the Users any advice or counsel, legal or
otherwise, with regard thereto.</Paragraph>

<SectionHeading>Disclaimer</SectionHeading>

<Paragraph>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our Website and the use of this Website. Nothing in this disclaimer will:</Paragraph>

<Paragraph>
<List>
    <li>limit or exclude our or your liability for death or personal injury;</li>
    <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
    <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
    <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
</List>
</Paragraph>

<Paragraph>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.</Paragraph>

<Paragraph>As long as the Website and the information and services on the Website are provided free of charge, we will not be liable for any loss or damage of any nature.</Paragraph>      
      
    </Wrapper>
  )
}

export default Terms