import { css } from 'styled-components'
import { up, down } from 'styled-breakpoints'
import {
  transparentize as fade,
  darken,
  lighten,
  getLuminance,
  between,
  rem,
} from 'polished'

import { breakpoints } from './breakpoints'

export const contentWrapper = css`
  width: 100%;
  max-width: ${({ theme }) => theme.contentWidth + 2 * 20}px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;

  ${up('sm')} {
    padding-left: 50px;
    padding-right: 50px;
    max-width: ${({ theme }) => theme.contentWidth + 2 * 50}px;
  }

  ${up('md')} {
    padding-left: 130px;
    padding-right: 130px;
    max-width: ${({ theme }) => theme.contentWidth + 2 * 130}px;
  }
`

export const surface = ({ theme }) => {
  const surfaceLuminance = getLuminance(theme.surface.regular)
  const brightness = surfaceLuminance + (theme.id === 'NIGHT' ? 1 : 0)
  const lighter = lighten(0.05, theme.surface.regular)
  const darker = darken(0.05, theme.surface.regular)
  const background = theme.id === 'NIGHT' ? lighter : darker

  return css`
    background-color: ${fade(0.05, background)};

    @supports (backdrop-filter: blur(10px)) {
      background-color: ${fade(0.4, theme.surface.regular)};
      backdrop-filter: blur(25px) brightness(${brightness}) saturate(2);
    }
  `
}

export const ellipsis = css`
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: ${({ maxlines }) => maxlines};
  -webkit-box-orient: vertical;
  max-height: ${({ lineHeight, maxlines }) => (lineHeight || 1.2) * maxlines}em;
  overflow: hidden;
  text-overflow: ellipsis;
`

const tracking = fontSize =>
  `${-0.0223 + 0.185 * Math.exp(-0.1745 * fontSize)}em`

const trackingBetween = (x1, x2, y1, y2, mid) => {
  const slope = (x2 - x1) / (y2 - y1)
  const base = x2 - slope * y2

  return tracking(slope * mid + base)
}

/**
 *
 * @param {Number} min font-size in pixels
 * @param {Number} max font-size in pixels
 */
export const fluidFont = (min, max) => css`
  ${down('sm')} {
    font-size: ${rem(min)};
    letter-spacing: ${tracking(min)};
  }
  ${up('sm')} {
    font-size: ${between(
      rem(min),
      rem(max),
      rem(breakpoints.sm),
      rem(breakpoints.lg)
    )};
    letter-spacing: ${trackingBetween(
      min,
      max,
      breakpoints.sm,
      breakpoints.lg,
      (breakpoints.sm + breakpoints.md) / 2
    )};
  }
  ${up('md')} {
    letter-spacing: ${trackingBetween(
      min,
      max,
      breakpoints.sm,
      breakpoints.lg,
      (breakpoints.md + breakpoints.lg) / 2
    )};
  }
  ${up('lg')} {
    font-size: ${rem(max)};
    letter-spacing: ${tracking(max)};
  }
`

export const link = css`
  font-family: var(--font-modern);
  appearance: none;
  border: none;
  background-color: transparent;
  border-radius: 0;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  color: ${({ theme }) => theme.primary};
  transition: color 80ms ease-out;
  text-decoration: none;
  padding: 0;
  margin: 0;

  :active {
    color: ${({ theme }) => fade(0.5, theme.primary)};
  }

  :disabled {
    color: ${({ theme }) => fade(0.5, theme.title)};
  }
`
