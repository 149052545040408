export const readJSONFile = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onabort = reject
    reader.onerror = reject
    reader.onload = () => {
      try {
        resolve(JSON.parse(reader.result))
      } catch (error) {
        reject(error)
      }
    }

    reader.readAsText(file)
  })
