import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { link } from '../styles'
import { PRIVACY_URL, TERMS_URL } from '../utils/url'

const Text = styled.p`
  color: ${({ theme }) => theme.text.subtle};
  text-align: center;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.7;
  margin: 0;
`

const Wrapper = styled.footer`
  background-color: ${({ theme }) => theme.surface.regular};
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
`

const Footerlist = styled.ul`
    width: 100%;
    padding-left: 3rem;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    justify-content: center;
`

const Footeritem = styled.li`
  margin: 0.125rem 2.0rem 0.85rem;
  white-space: nowrap;
  
  :nth-last-child(1) {
    flex: 1;
    text-align: center;
    
  }
`


const StyledLink = styled(Link)`
  ${link};
`

const Footer = ({ children, ...restProps }) => (
  <Wrapper {...restProps}>
    <Footerlist>
        <Footeritem>
            <StyledLink to="/">Optimize</StyledLink>
        </Footeritem>
        <Footeritem>
            <StyledLink to={TERMS_URL}>Terms & Conditions</StyledLink>
        </Footeritem>
        <Footeritem>
            <StyledLink to={PRIVACY_URL}>Privacy Policy</StyledLink>
        </Footeritem>
        <Footeritem>
            <Text>
              Created by the Advanced Concepts Team.
              <br />
              Copyright © {new Date().getFullYear()} European Space Agency. All rights
              reserved.
            </Text>
        </Footeritem>        
    </Footerlist>
  </Wrapper>
)

export default Footer
