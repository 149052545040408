import React from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { ellipsis, transparentize as fade } from 'polished'

import { surface, fluidFont } from '../styles'

const Item = styled(Link)`
  ${surface};
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
  transition: color 80ms ease-out;
  pointer-events: ${({ active }) => (active ? 'none' : 'inherit')};
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  color: ${({ theme, active }) =>
    active ? fade(0.5, theme.title) : theme.title};

  &:active {
    color: ${({ theme }) => fade(0.5, theme.title)};
  }

  ${fluidFont(17, 17)};
  font-weight: 500;
  padding: 10px 20px;
  min-width: 130px;
  ${ellipsis(220)};
  user-select: none;
  margin-right: 20px;

  :last-child {
    margin-right: 0;
  }
`

Item.defaultProps = {
  variant: 'solid',
}

export default props => {
  const { pathname } = useLocation()

  // 'true' as string and undefined prevent console error
  const active = pathname === props.to ? 'true' : undefined

  return <Item {...props} active={active} />
}
