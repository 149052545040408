import React from 'react'
import styled from 'styled-components'

import Title from './Title'
import TitleRowLink from './TitleRowLink'
import Chevron from '../../icons/Chevron/right'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`

const TitleRow = ({
  register: isRegister,
  loginLink,
  registerLink,
  ...restProps
}) => (
  <Wrapper {...restProps}>
    <Title>{isRegister ? 'Register' : 'Login'}</Title>
    <div css="flex-grow: 1;" />
    <TitleRowLink to={isRegister ? loginLink : registerLink}>
      {isRegister ? 'Login' : 'Register'} <Chevron />
    </TitleRowLink>
  </Wrapper>
)

export default TitleRow
