import React, { useState, useEffect, useRef, useCallback } from 'react'
import { ApolloLink } from "apollo-link";
import { useMutation, useApolloClient } from '@apollo/react-hooks'
import localForage from 'localforage'

import REFRESH from './queries/Refresh.gql'
import REVOKE from './queries/Revoke.gql'
import CURRENT_USER from './queries/CurrentUser.gql'
import Loading from '../shared/loading'

export const AuthContext = React.createContext()

// Update access token every 4.5 minutes,
// 5 min is the lifetime of an access token.
const RENEWAL_INTERVAL_TIME = 4.5 * 60000
const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN_KEY'

let accessToken

const AuthProvider = ({ children }) => {
  const refreshToken = useRef()
  const receivedAt = useRef()
  const [loading, setLoading] = useState(true)
  const [editor, setEditor] = useState()
  const [userId, setUserId] = useState()
  const client = useApolloClient()
  const [refresh] = useMutation(REFRESH)
  const [revoke] = useMutation(REVOKE)

  const renewTokens = useCallback(async () => {
    const {
      data: { refreshToken: tokenData },
      errors,
    } = await refresh({
      variables: { refreshToken: refreshToken.current },
    })

    if (errors) throw errors

    accessToken = tokenData.token
    refreshToken.current = tokenData.refreshToken
    receivedAt.current = Date.now()
  }, [refresh])

  const postLogin = useCallback(async tokenAuth => {
    accessToken = tokenAuth.token
    refreshToken.current = tokenAuth.refreshToken
    receivedAt.current = Date.now()

    await localForage.setItem(REFRESH_TOKEN_KEY, tokenAuth.refreshToken)

    setUserId(tokenAuth.user.id)

    return tokenAuth.user.id
  }, [])

  const logout = useCallback(async () => {
    const revoking = Promise.all([
      localForage.removeItem(REFRESH_TOKEN_KEY),
      revoke({ variables: { refreshToken: refreshToken.current } }),
      client.resetStore(),
    ])

    refreshToken.current = undefined
    receivedAt.current = undefined
    await revoking

    setUserId(undefined)
  }, [client, revoke])

  // check if refresh token is present and valid
  useEffect(() => {
    const getAuthenticationState = async () => {
      refreshToken.current = await localForage.getItem(REFRESH_TOKEN_KEY)

      if (!refreshToken.current) {
        setLoading(false)
        return
      }

      try {
        await renewTokens()

        const {
          data: { currentUser: user },
          errors: userErrors,
        } = await client.query({ query: CURRENT_USER })

        if (userErrors) throw userErrors

        setUserId(user.id)
        setEditor(user.editor)
      } catch (error) {
        await localForage.removeItem(REFRESH_TOKEN_KEY)
      } finally {
        setLoading(false)
      }
    }

    getAuthenticationState()
  }, [client, renewTokens])

  useEffect(() => {
    if (userId) {
      let intervalId = setInterval(renewTokens, RENEWAL_INTERVAL_TIME)

      const onFocus = () => {
        if (Date.now() - receivedAt.current >= RENEWAL_INTERVAL_TIME) {
          renewTokens()
        }
      }

      window.addEventListener('focus', onFocus)

      return () => {
        clearInterval(intervalId)
        window.removeEventListener('focus', onFocus)
      }
    }
  }, [renewTokens, userId])

  if (loading) return <Loading />

  const value = {
    userId,
    editor,
    postLogin,
    logout,
    renewTokens,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}


export const authLink = new ApolloLink((operation, forward) => {
  if (accessToken && operation.operationName !== 'Refresh') {
    // add the authorization to the headers
    operation.setContext({
      headers: {
        authorization: `Bearer ${accessToken}`,
      }
    });
  }

  return forward(operation);
})

export default AuthProvider
