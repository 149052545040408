import React from 'react'

import Base from '../../Base'

export default props => (
  <Base {...props} boxWidth={2565}>
    <path
      d="M977 -263c-130 0 -201 43 -270 120l-364 409c-70 78 -88 128 -88 234v437c0 106 18 156 88 234l364 409c69 76 140 120 270 120h611c130 0 201 -44 270 -120l364 -409c70 -78 88 -128 88 -234v-437c0 -106 -18 -156 -88 -234l-364 -409c-69 -77 -140 -120 -270 -120h-611
zM1283 579c57 0 90 33 92 93l15 458c3 61 -44 106 -108 106c-65 0 -111 -44 -108 -105l14 -459c3 -60 36 -93 95 -93zM1283 204c66 0 121 46 121 114s-55 114 -121 114c-68 0 -122 -47 -122 -114s55 -114 122 -114z"
    />
  </Base>
)
